import axios from 'axios';
import React from 'react';
import { Link} from 'react-router-dom'

import './style.css';
import LoadingRing from 'components/LoadingRing'
import MusioLogo from 'components/MusioLogo';
import ErrorMessage from 'components/ErrorMessage';
import MusioButton from 'components/MusioButton';
import { loginWithToken } from 'store/authentication';
import { startRecurring } from 'store/resources';

//TODO: this desparately needs a refactor
// 1. turn into functional component
// 2. this page handles trial/subscription verification, verification of incomplete accounts (made in admin) which need a form  

//this page needs to extract token, ping server to validate. if it is valid, then display this. otherwise
//say 'this token is invalid'
//2 endpoints. /get to forward token and verify.
// on submit, the post to the verify endpoint
class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verifySubmitted: false,
      verifyError:'',
      token: '',
      contactName: null,
      password: null,
      tokenValid: null,
      isLoading: true,
      needContactName: false,
      needPassword: false,
      headerText: 'click below to verify your account.',
      hasTrial: false,
      next: null
    };

    this.postVerification = this.postVerification.bind(this);
    this.verifyFormClicked = this.verifyFormClicked.bind(this);

    //Rewardful scripts are loaded in the header
    window.rewardful('ready', () => {
      if (window.Rewardful.referral) {
        this.state.rewardfulReferral = window.Rewardful.referral;
      }
    });
  };

  componentDidMount() {
    //extract params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    this.setState({tokenValid: true, token: token});

    //GET to api/v1/verify?token=731479119...
    //wait until this call is done before showing stuff on the page
    axios.get('/api/v1/verify', {params: {token}})
    .then(resp => {
      if (resp.data.error) {
        //'this token is invalid' on page
        window.location.href='/error';
        return;
      }

      //ready for user/auto verification
      if (resp.data.success) {
        if (resp.data.verificationNext === 'trial') {
          this.setState({headerText:'Click below to start your free trial!', hasTrial: true});
          this.setState({isLoading: false});
        }
        else
        {
          this.postVerification();
        }
        return;
      }

      //account needs data
      let requiredData = resp.data.required;
      if (requiredData.length > 0) {
      //if length is bigger than zero, display different page
        if (requiredData.includes('contactName')) {
          this.setState({needContactName: true, contactName: '', headerText: 'Please complete your account', isLoading: false});
        }
        if (requiredData.includes('password')) {
          this.setState({needPassword: true, password: '', headerText: 'Please complete your account', isLoading:false});
        }
      }
      else
      {
        
      }


    }).catch(err=>{
      //TODO redirect to login, they wil already be logged out
      window.location.href='/error';
    });
  };

  postVerification() {
    this.setState({isLoading:true, verifyError:''});
    
    //writes expression to console if statement returns false
    console.assert(this.state.token !== '', 'no token found.');

    //Empty input and password check
    //check for if we need a contact name and is it filled?
    if (this.state.contactName !== null && this.state.contactName == '') {
      this.setState({isLoading:false, verifyError: 'please fill out your contact name.'});
      return;
    }
    //do we need a pw and is it valid
    if (this.state.password !== null) {
      if (this.state.password == '') {
        this.setState({isLoading:false, verifyError: 'please fill out your password.'});
        return;
      }
      if (this.state.password.length < 8){
        this.setState({isLoading:false, verifyError: 'password must be at least 8 characters long.', isLoading:false});
        return;
      } else if (this.state.password.length > 64){
        this.setState({isLoading:false, verifyError: 'password cannot be more than 64 characters long.', isLoading:false});
        return;
      }
    }

    let dataToSend = {token: this.state.token};
    if (this.state.contactName !== null) {
      dataToSend.contactName = this.state.contactName.toString();
    }
    if (this.state.password !== null) {
      dataToSend.password = this.state.password.toString();
    }
    if (this.state.rewardfulReferral) {
      dataToSend.referral = this.state.rewardfulReferral;
    }

    axios({method: "POST", url: "/api/v1/verify",
        data: dataToSend
    })
    .then(resp=>{
      this.setState({verifySubmitted: true, isLoading:false});
      this.setState({next: resp.data.verificationNext});
      console.log(resp.data.verificationNext);
      window.dataLayer.push({
        'event': 'formInteraction',
        formInteraction: {
          property: 'portal',
          form: 'signuptrial'
        }
      });

      //trigger login
      setTimeout(()=>{ 
        loginWithToken(resp.data.token)
        .then((res)=>{
          console.log(this.state.next);
          // if (this.state.next === 'monthlySubscription') {
          //   startRecurring('monthly'); //TODO: referral? coupon?
          //   //will redirect to checkout
          // }
          // else if (this.state.next === 'annualSubscription') {
          //   startRecurring('annual'); //TODO: referral? coupon?
          //   //will redirect to checkout
          // }
          if (this.state.next === 'login') {
            window.location.href = '/';
          }
          else {
            window.location.href = '/';
          }
        })
        .catch(err => {
          console.log('auto login failed :/')
        })
      }, 1000);
      
    })
    .catch(err => {
      let status = err.response.status;
      if (status == 422) {
        let constraint = err.response.data.error.passwordConstraint;
        this.setState({verifyError: constraint, isLoading:false});
      } else {
        this.setState({verifyError:'token was invalid.', isLoading:false});
      }
    });
  }

  verifyFormClicked(e) {
    e.preventDefault();
    this.postVerification();
  };

  render() {
    if (this.state.verifySubmitted) {
      return (
        <div className='onboarding-container'>
          <MusioLogo />
          <div className="onboarding-spacer" />

          {this.state.next === "monthlySubscription" || this.state.next === "annualSubscription" ?
            <h1 className='onboarding-header'>Finishing your account setup...</h1>
          :
            <h1 className='onboarding-header'>You’re all set! Logging you in to the portal.</h1>
          }
          <div className="onboarding-spacer" />

          {/* <Link to="/login">
            <MusioButton text="log in" />
          </Link> */}
        </div>
      );
    }


    let getContactName = <div>
      <input className='input' id='input-name' type='text' placeholder=' name...' onChange={(e)=>this.setState({contactName: e.target.value})}/>
    </div>;

    let getPassword = <div>
      <input className='input' type='password' placeholder=' password...' onChange={(e)=>this.setState({password: e.target.value})}/>
    </div>;

    let formSubmitBtn = <span>verify</span>;
    if (this.state.isLoading) {
      formSubmitBtn = <LoadingRing/>
    } else if (this.state.hasTrial) {
      formSubmitBtn = <span>start free trial</span>;
    }

    if (!this.state.verifySubmitted && !this.state.isLoading){
      return (
        <div className='onboarding-container'>
          <MusioLogo />
          <div className="onboarding-spacer" />

          <ErrorMessage text={this.state.verifyError} />

          <h1 className='onboarding-header'>{this.state.headerText}</h1>

          <form onSubmit={this.verifyFormClicked} className='onboarding-form'>
            {this.state.needContactName? getContactName : <span/> }
            {this.state.needPassword? getPassword : <span/>}
            <div className="onboarding-spacer" />
            <div><MusioButton text={formSubmitBtn} /></div>
          </form>
        </div>
      );
    }

    return (
      <div className= 'verify-loading'>
        <span/>
        {/* <LoadingSpinner/> */}
      </div>
    );
  };
};

export default Verify;
